import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import api from "../../../helpers/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGoogle,
    faInstagram,
    faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { SpotifyLoginButton } from "../../../components/LoginButtons/SpotifyLoginButton";
import { MetaLoginButton } from "../../../components/LoginButtons/MetaLoginButton";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useAuth } from "../utils/AuthProvider";
import { GoogleLoginCustomButton } from "../../../components/LoginButtons/GoogleLoginCustomButton";
import { toAbsoluteUrl } from "../../../helpers/utils/toAbsoluteUrl";

/**
 * Renderiza os botões de login com redes sociais sem o usuário precisar logar. Vai ser usado
 * apenas para embed no app pros usuários conseguirem conectar suas redes sociais.
 * @returns {JSX.Element}
 */
export function SocialLogin() {
    const location = useLocation();
    const { t } = useTranslate();
    const {
        currentUser,
        setCurrentUser,
        saveAuth,
        currentArtist,
        setCurrentArtist,
    } = useAuth();
    const [token, setToken] = useState(null);
    const { artistId } = useParams();

    useEffect(() => {
        if (artistId && token) {
            getCurrentArtist();
            getUser();
        }
    }, [token, artistId]);

    /**
     * Tenta obter o artista da requisição com base no id indicado
     * @returns {Promise<void>}
     */
    const getCurrentArtist = async () => {
        try {
            const response = await api.get(`core/artists/${artistId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            let artist = response.data;
            setCurrentArtist({
                id: artist.id,
                name: artist.name,
                avatar: artist.profile_picture,
                type: artist.get_type_display,
                appOniHolderId: artist.app_oni_id,
                slug: artist.invitation_page_slug,
                spotifyId: artist.spotify_id,
                healthcheckCompletion: artist.healthcheck_completion,
                canCreateOnesheet: artist.can_create_onesheet,
                active: true,
                facebook_long_lived_token: artist.facebook_long_lived_token,
                google_refresh_token: artist.google_refresh_token,
            });
            localStorage.setItem("aid", artistId);
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * Tenta obter o usuário da requisição com base no token indicado
     * @returns {Promise<void>}
     */
    const getUser = async () => {
        try {
            const userResponse = await api.get("/core/users", {
                // Pega o user relacionado ao token passado por parametro
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            let userData = userResponse.data[0];
            setCurrentUser({
                id: userData.id,
                email: userData.email,
                first_name: userData.first_name,
                last_name: userData.first_name,
                hasAcceptedTerms: userData.has_accepted_terms,
            });
            saveAuth({
                api_token: token,
                refreshToken: "",
            });
        } catch (e) {
            window.location.replace("/error/404");
        }
    };

    /**
     * Obtém o parâmetro "at" da URL (que corresponde ao access token) e faz uma
     * chamada para o backend para buscar o usuário correspondente. Caso não
     * haja o parâmetro ou não seja possível encontrar o usuário, redireciona
     * para 404.
     */
    useEffect(() => {
        // Obtém o parâmetro "at" da URL
        const searchParams = new URLSearchParams(location.search);
        const at = searchParams.get("at");
        if (!at) window.location.replace("/error/404");
        setToken(at);
    }, [location]);

    return (
        <>
            {!(currentUser && currentArtist) ? (
                <div className="flex items-center justify-center min-h-[100vh]">
                    <LoadingSpinner size={"2xl"} />
                </div>
            ) : (
                <div className="flex flex-col gap-4 border-top p-9 space-y-8 pb-0">
                    {false && (
                        <>
                            {/* Spotify */}
                            <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
                                {/* LADO ESQUERDO DA ROW: ÍCONE DA REDE, NOME E PROPÓSITO DA CONEXÃ0 */}
                                <div className="flex items-start self-baseline md:flex-2">
                                    {/* LOGO DA REDE SOCIAL */}
                                    <FontAwesomeIcon
                                        icon={faSpotify}
                                        color="#1DB954"
                                        className="mr-5 text-4xl"
                                    />
                                    <div className="flex flex-column">
                                        {/* TITULO DA REDE SOCIAL */}
                                        <span className="font-bold text-slate-50 text-lg mb-3">
                                            {t(
                                                "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.SPOTIFY"
                                            )}{" "}
                                            {currentUser.spotifyId ? (
                                                <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                    color="green"
                                                    className="ml-2"
                                                />
                                            ) : null}
                                        </span>
                                        {/* EXPLICACAO DO PQ QUEREMOS QUE O ARTISTA CONECTE COM AQUELA REDE SOCIAL */}
                                        <span className="font-bold text-gray-500 text-base">
                                            {t(
                                                "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.SPOTIFY.TEXT"
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {/* LADO DIREITO DA ROW: STATUS DA CONEXÃO E BOTÃO DE AÇÃO (CONECTAR/DESCONECTAR) */}
                                <div className="w-full flex-1 max-w-40 md:flex-1 lg:max-w-[256px]">
                                    <SpotifyLoginButton />
                                </div>
                            </div>
                        </>
                    )}
                    {/*Facebook*/}
                    <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
                        {/* LADO ESQUERDO DA ROW: ÍCONE DA REDE, NOME E PROPÓSITO DA CONEXÃ0 */}
                        <div className="flex items-start md:flex-2 w-full">
                            {/* LOGO DA REDE SOCIAL */}
                            <FontAwesomeIcon
                                icon={faInstagram}
                                // instagram collor
                                color="#E1306C"
                                className="mr-5 text-4xl"
                            />
                            <div className="flex flex-column">
                                {/* TITULO DA REDE SOCIAL */}
                                <span className="font-bold text-slate-50 text-lg mb-3">
                                    {t(
                                        "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.INSTAGRAM"
                                    )}{" "}
                                    {currentUser.facebookToken ? (
                                        <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            color="green"
                                            className="ml-2"
                                        />
                                    ) : null}
                                </span>
                                {/* EXPLICACAO DO PQ QUEREMOS QUE O ARTISTA CONECTE COM AQUELA REDE SOCIAL */}
                                <span className="font-bold text-white text-base">
                                    {t(
                                        "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.INSTAGRAM.TEXT"
                                    )}
                                </span>
                            </div>
                        </div>
                        {/* LADO DIREITO DA ROW: STATUS DA CONEXÃO E BOTÃO DE AÇÃO (CONECTAR/DESCONECTAR) */}
                        <div className="w-full flex-1 max-w-40 md:flex-1 lg:max-w-[256px]">
                            <MetaLoginButton />
                        </div>
                    </div>
                    {/* Google */}
                    {/*<div className="flex flex-col md:flex-row gap-4 justify-between items-center">*/}
                    {/*    /!* LADO ESQUERDO DA ROW: ÍCONE DA REDE, NOME E PROPÓSITO DA CONEXÃ0 *!/*/}
                    {/*    <div className="flex items-start self-baseline md:flex-2">*/}
                    {/*        /!* LOGO DA REDE SOCIAL *!/*/}
                    {/*        <img*/}
                    {/*            src={toAbsoluteUrl(*/}
                    {/*                "/media/icons/googleColoredLogo.png"*/}
                    {/*            )}*/}
                    {/*            alt="Google Logo"*/}
                    {/*            width="27px"*/}
                    {/*            className="mr-5"*/}
                    {/*        />*/}
                    {/*        <div className="flex flex-column">*/}
                    {/*            /!* TITULO DA REDE SOCIAL *!/*/}
                    {/*            <span className="font-bold text-slate-50 text-lg mb-3">*/}
                    {/*                {t(*/}
                    {/*                    "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.GOOGLE"*/}
                    {/*                )}{" "}*/}
                    {/*                {currentUser.googleRefreshToken ? (*/}
                    {/*                    <FontAwesomeIcon*/}
                    {/*                        icon={faCircleCheck}*/}
                    {/*                        color="green"*/}
                    {/*                        className="ml-2"*/}
                    {/*                    />*/}
                    {/*                ) : null}*/}
                    {/*            </span>*/}
                    {/*            /!* EXPLICACAO DO PQ QUEREMOS QUE O ARTISTA CONECTE COM AQUELA REDE SOCIAL *!/*/}
                    {/*            <span className="font-bold text-gray-500 text-base">*/}
                    {/*                {t(*/}
                    {/*                    "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.GOOGLE.TEXT"*/}
                    {/*                )}*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    /!* LADO DIREITO DA ROW: STATUS DA CONEXÃO E BOTÃO DE AÇÃO (CONECTAR/DESCONECTAR) *!/*/}
                    {/*    <div className="w-full flex-1 max-w-40 md:flex-1 lg:max-w-[256px]">*/}
                    {/*        <GoogleLoginCustomButton />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            )}
        </>
    );
}
