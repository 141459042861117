import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import { CoreRoutes } from "./specificRoutes/CoreRoutes";
import { useAuth } from "../modules/auth";
import { AcademyRoutes } from "./specificRoutes/AcademyRoutes";
import { InvitesRoutes } from "./specificRoutes/InvitesRoutes";
import { CatalogRoutes } from "./specificRoutes/CatalogRoutes";
import { DashboardPage } from "../modules/dashboard/DashboardPage";
import { OnesheetRoutes } from "./specificRoutes/OnesheetRoutes";
import { DatascienceRoutes } from "./specificRoutes/DatascienceRoutes";

/**
 * Carrega as rotas que só podem existir pro usuário logado
 * @param {Object} currentClient
 * @returns {JSX.Element}
 */
const appSpecificRoutes = (currentClient) => {
    /* appRoutes vai carregar as rotas dependentes de Módulo Pago. Cada chave do objeto é um código do módulo que existe,
     * inclusive, no banco de dados. O objeto currentClient contém a informacao de quais módulos o cliente possui na
     * conta dele, e a partir disso, carregamos apenas as rotas correspondentes.
     */
    const appRoutes = {
        INV: (
            <Route
                key="r-invites"
                path="invites/*"
                element={<InvitesRoutes />}
            />
        ), // INV: módulo de convites
        CORE: <Route key="r-core" path="core/*" element={<CoreRoutes />} />, // CORE: Módulo de dados gerais
        DTSCI: (
            <Route
                key="r-dtsci"
                path="datascience/*"
                element={<DatascienceRoutes />}
            />
        ), // DTSC: Módulo de Data Science
        CTLOG: (
            <Route
                key="r-projects"
                path="projects/*"
                element={<CatalogRoutes />}
            />
        ), // CTLOG: Módulo de projetos
        KBASE: (
            <Route key="r-ac" path="academy/*" element={<AcademyRoutes />} />
        ), // KBASE: Módulo de Base de Conhecimento (cursos, tutoriais...)
        ONSHE: (
            <Route key="osh" path="onesheet/*" element={<OnesheetRoutes />} />
        ), // ONSHE: Módulo de Onesheets
    };
    return (
        <>
            {currentClient.available_features.map(
                (module) => appRoutes[module]
            )}
        </>
    );
};

const PrivateRoutes = () => {
    const { currentUser } = useAuth();
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect pro Dashboard depois de login/cadastro com sucesso */}
                <Route path="auth/*" element={<Navigate to="/dashboard" />} />
                {/* Paths 'default', redirecionando para a dashboard */}
                <Route path="" element={<Navigate to="/dashboard" />} />
                <Route path="home" element={<Navigate to="/dashboard" />} />
                <Route path="dashboard" element={<DashboardPage />} />
                {[396, 450].includes(currentUser?.id) && (
                    <Route
                        key="r-invites"
                        path="invites/*"
                        element={<InvitesRoutes />}
                    />
                )}
                {/* Se não bater em nenhum path listado acima, é 404 */}
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Route>
            {/*<Route element={<HeadlessLayout/>}>*/}
            {/*/!* Redirect para telas que não devem ter o cabeçalho *!/*/}
            {/*</Route>*/}
        </Routes>
    );
};

export { PrivateRoutes };
