import React from "react";

const AppLoadingGif = () => {
    return (
        <video autoPlay loop muted playsInline>
            <source src="/media/logos/loadinggif.webm" type="video/webm" />
            <img src="/media/logos/loadinggif.gif" alt="Loading Gif" />
        </video>
    );
};

export { AppLoadingGif };
