import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { toast } from "react-toastify";
import api, { form_api } from "../../../helpers/Api";
import { useAlert } from "../../../helpers/utils/CustomAlert";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { InputDefault } from "../../../components/Form/InputDefault";
import { CustomSaveButton } from "../../../components/CustomSaveButton";
import { makeFormDataBody } from "../../../helpers/utils/formUtils";
import { AppLoadingGif } from "../../../components/AppLoadingGif";

const NewUserPurchaseSuccess = () => {
    const location = useLocation();
    const { alert } = useAlert();
    const { t } = useTranslate();
    const initialFormData = {
        email: null,
        name: null,
        password: "",
        passwordConfirmation: "",
        socialLogin: false,
    };
    const [form, setForm] = useState(initialFormData);
    const [formSending, setFormSending] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        number: false,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [fieldErrors, setFieldErrors] = useState([]);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

    const getCustomerEmailAddress = async (sessionId) => {
        try {
            const response = await api.get(
                `core/get-email-from-stripe-session-id/${sessionId}`
            );
            if (response.data.redirect)
                window.location.replace("/download-app");
            setForm({
                ...form,
                email: response.data.email,
                name: response.data.name,
            });
        } catch (e) {
            console.error(e);
            alert.fire(t("GENERAL.OOPS"), t("GENERAL.ACTION-FAILED"), "danger");
        }
    };

    /**
     * Obtém o parâmetro "stid" da URL, que contem o id da tabela de preços do Stripe.
     */
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get("sid");
        if (!sessionId) window.location.replace("/error/404");
        getCustomerEmailAddress(sessionId);
    }, [location]);

    // função que lida com a senha e faz o teste no regex
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;

        const lengthValid = newPassword.length >= 8;
        const letterValid = /[a-zA-Z]/.test(newPassword);
        // const uppercaseValid = /[A-Z]/.test(newPassword);
        // const lowercaseValid = /[a-z]/.test(newPassword);
        // const specialCharValid = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
        //     newPassword
        // );
        const numberValid = /\d/.test(newPassword);

        setPasswordValidation({
            length: lengthValid,
            // uppercase: uppercaseValid,
            // lowercase: lowercaseValid,
            // specialChar: specialCharValid,
            letter: letterValid,
            number: numberValid,
        });
    };

    /**
     * verifica se todos os requisitos de senha estão válidos
     */
    const isAllValid = Object.values(passwordValidation).every(
        (isValid) => isValid
    );

    /**
     * UseEffect que fica observando os requisitos da senha
     * se não forem atendidos, o botão de continuar fica disabled
     */
    useEffect(() => {
        setSendButtonDisabled(!isAllValid);
    }, [setSendButtonDisabled, isAllValid]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (form.password !== form.passwordConfirmation) {
            alert.fire(
                "As senhas não coincidem!",
                "Confira as senhas informadas",
                "error"
            );
            return;
        }
        setFormSending(true);
        let data = makeFormDataBody(form);
        try {
            await form_api.post("auth/set-password-for-new-user", data);
            alert
                .fire(
                    t("GENERAL.SUCCESS"),
                    "Seu usuário foi criado!",
                    "success"
                )
                .then(() => window.location.replace("/download-app"));
        } catch (e) {
            console.error(e);
            alert.fire(t("GENERAL.OOPS"), t("GENERAL.ACTION-FAILED"), "danger");
            setFormSending(false);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen overflow-hidden">
            {form?.email ? (
                <div className="flex flex-column justify-center">
                    <img
                        src="/media/logos/default-dark.svg"
                        alt="AmplifyUs Logo"
                        style={{ height: "50px", width: "auto" }}
                    />
                    <div className="flex flex-column my-10 text-center gap-5">
                        <span className="text-3xl font-bold text-white">
                            Obrigado, {form.name}!
                        </span>
                        <span className="text-xl text-white">
                            Seu pagamento foi confirmado.
                        </span>
                        <span className="text-xl text-white">
                            Para acessar a plataforma, utilize o email{" "}
                            {form.email} e defina uma senha abaixo.
                        </span>
                    </div>
                    <div className="mb-10">
                        <InputDefault
                            idPassword="showPassword"
                            id="password"
                            name="password"
                            value={form.password}
                            type={showPassword ? "text" : "password"}
                            handleChange={(e) => {
                                setForm({ ...form, password: e.target.value });
                                handlePasswordChange(e);
                            }}
                            placeholder={t("AUTH.INPUT.PASSWORD")}
                            fieldError={fieldErrors}
                            error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                        />
                    </div>
                    <div className="mb-10">
                        <InputDefault
                            idPassword="showPassword"
                            id="passwordConfirmation"
                            isPassword
                            name="passwordConfirmation"
                            value={form.passwordConfirmation}
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                            type={showPassword ? "text" : "password"}
                            handleChange={(e) => {
                                setForm({
                                    ...form,
                                    passwordConfirmation: e.target.value,
                                });
                            }}
                            placeholder={"Confirme a sua senha"}
                            fieldError={fieldErrors}
                            error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                        />
                    </div>
                    <label className="form-label fs-6 fw-bolder text-dark">
                        {t("AUTH.PASSWORD-REQUIREMENTS")}
                    </label>
                    <ul>
                        <li
                            className={
                                passwordValidation.length
                                    ? "text-success"
                                    : "text-danger"
                            }
                        >
                            {t("AUTH.MINIMUM-CHARACTERS-REQUIREMENT")}
                        </li>
                        <li
                            className={
                                passwordValidation.number
                                    ? "text-success"
                                    : "text-danger"
                            }
                        >
                            {t("AUTH.NUMBER-REQUIREMENT")}
                        </li>
                        <li
                            className={
                                passwordValidation.letter
                                    ? "text-success"
                                    : "text-danger"
                            }
                        >
                            Uma letra
                        </li>
                    </ul>

                    <CustomSaveButton
                        type="submit"
                        onClick={handleSubmit}
                        buttonText={"Confirmar"}
                        className="btn btn-lg btn-primary w-100 my-10"
                        formSending={formSending}
                        disabled={sendButtonDisabled}
                        hasError={hasError}
                        setHasError={setHasError}
                    />
                </div>
            ) : (
                <AppLoadingGif />
            )}
        </div>
    );
};

export { NewUserPurchaseSuccess };
