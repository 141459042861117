import React from "react";
import { Grid } from "@nextui-org/react";

const AppDownloadButtons = () => {
    const styles = {
        image: {
            width: "auto",
            height: "50px",
        },
    };
    return (
        <Grid.Container gap={2} justify="center">
            <Grid>
                <a
                    href="https://apps.apple.com/br/app/amplifyus/id6471351084?l=en-GB" // Substitua pelo link da App Store
                >
                    <img
                        src="/media/logos/disponivel-na-app-store-botao.png"
                        alt="Baixar na App Store"
                        style={styles.image}
                    />
                </a>
            </Grid>
            <Grid>
                <a
                    href="https://play.google.com/store/apps/details?id=app.amplifyus" // Substitua pelo link do Google Play
                >
                    <img
                        src="/media/logos/disponivel-google-play-badge.png"
                        alt="Disponível no Google Play"
                        style={styles.image}
                    />
                </a>
            </Grid>
        </Grid.Container>
    );
};

export default AppDownloadButtons;
